/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import Clock from 'assets/svg/Clock.svg';
import CandleSticks from 'assets/svg/CandleSticks.svg';
import ScoreBoard from 'assets/svg/ScoreBoard.svg';
// import CheckIcon from 'assets/svg/CheckIcon.svg';
import {
  PageLayout,
  RecipeSlider,
  PageHeading,
  SocialShare,
  BreadCrumbs,
} from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RecipeDetailTypes } from 'types';

const RecipeDetail: FC<RecipeDetailTypes> = ({ data }: any) => {
  const {
    Learninghub: { slug: learningHubSlug, title: learningHubTitle },
    RecipeDetails: {
      title,
      duration,
      image,
      difficulty,
      calories,
      content,
      recipe_tags,
      seo,
    },
    RecipeList,
    RecipesPage: { slug: recipesUrl, title: recipesTitle, description, banner },
  } = data;
  const recipeImage: any = getImage(image?.localFile?.childImageSharp);
  const [relatedSlides, setRelatedSlides] = useState([]);
  const [Title, setTitle] = useState(false);
  useEffect(() => {
    const currentTags = recipe_tags.map((currentTag: any) => currentTag.title);
    const filteredRecipe = RecipeList.edges.filter((relatedSlide: any) => {
      let hasRelatedTag = false;
      currentTags.forEach((currentTag: any) => {
        hasRelatedTag =
          hasRelatedTag ||
          relatedSlide.node.recipe_tags
            .map((tag: any) => tag.title)
            .includes(currentTag);
      });
      return hasRelatedTag;
    });
    const recipedetailList = filteredRecipe.filter(
      (slide: any) => slide.node.title !== title
    );
    if (filteredRecipe.length > 0) {
      setTitle(true);
      setRelatedSlides(recipedetailList);
    }
  }, []);
  return (
    <PageLayout
      type="Recipe"
      className="RecipeDetail"
      isMainBannerNav={false}
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription ?? description}
      image={
        seo?.shareImage?.localFile?.publicURL ?? image?.localFile?.publicURL
      }
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Recipe Page"
    >
      <div className="container">
        <section className="recipe-detail">
          <BreadCrumbs
            breadCrumb={[
              { title: 'Home', slug: '/' },
              { title: learningHubTitle, slug: `/${learningHubSlug}` },
              {
                title: recipesTitle,
                slug: `/${learningHubSlug}/${recipesUrl}`,
              },
            ]}
          />
          <h1 className="recipe-detail-heading">{title}</h1>
          <div className="recipe-detail-wrapper-image">
            {recipeImage && (
              <GatsbyImage
                image={recipeImage}
                alt={image?.alternativeText ?? (title || '')}
              />
            )}
            {image?.localFile?.publicURL && (
              <SocialShare title={title} image={image?.localFile?.publicURL} />
            )}
          </div>
          <div className="recipe-detail-wrapper">
            <div className="recipe-detail-wrapper-content">
              <div className="recipe-detail-list">
                <div className="recipe-detail-list-item">
                  <div className="recipe-detail-content">
                    <div className="recipe-detail-content-item">
                      <Clock />
                      <p className="recipe-detail-content-name">Cooking Time</p>
                    </div>
                    <p className="recipe-detail-content-title">
                      {duration} minutes
                    </p>
                  </div>
                  <div className="recipe-detail-content">
                    <div className="recipe-detail-content-item">
                      <ScoreBoard />
                      <p className="recipe-detail-content-name">Difficulty</p>
                    </div>
                    <p className="recipe-detail-content-title difficulty">
                      {difficulty}
                    </p>
                  </div>
                  <div className="recipe-detail-content">
                    <div className="recipe-detail-content-item">
                      <CandleSticks />
                      <p className="recipe-detail-content-name">Calories</p>
                    </div>
                    <p className="recipe-detail-content-title">{calories}</p>
                  </div>
                </div>
              </div>
              <ReactMarkdown className="md-html">{content}</ReactMarkdown>
            </div>
          </div>
        </section>
        {Title && <PageHeading title="More Recipes" />}
      </div>
      <RecipeSlider
        relatedRecipes={relatedSlides}
        recipesUrl={`${learningHubSlug}/${recipesUrl}`}
      />
    </PageLayout>
  );
};

export default RecipeDetail;

export const query = graphql`
  query RecipeDetailsQuery($slug: String) {
    Learninghub: strapiLearningHubPage {
      slug
      title
    }
    RecipeDetails: strapiRecipe(slug: { eq: $slug }) {
      id
      title
      duration
      difficulty
      calories
      slug
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      content
      recipe_tags {
        title
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    RecipeList: allStrapiRecipe {
      edges {
        node {
          id
          strapiId
          title
          duration
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 518, height: 518)
              }
            }
          }
          recipe_tags {
            title
          }
        }
      }
    }
    RecipesPage: strapiRecipesPage {
      slug
      title
      description: pageDescription
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
